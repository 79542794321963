<template>
  <div class="aboutUs">
    <p class="top_title">联系我们</p>
    <ul>
      <li>地址：北京市朝阳区工体北路甲2号盈科中心A座21层2116</li>
      <li>电话: +86-10-5954-2700</li>
      <li>传真：+86-10-8565-6477</li>
      <li>Email：realplayer_cn@realnetworks.com</li>
      <li>微博：RealNetworks中国</li>
      <li>微信公众号：RealNetworks资讯</li>      
    </ul>
    <div class="msc-r">
      <img src="@/img/contractus02.png" alt="">
    </div>
    <p class="content">© 2022 RealNetworks 北京瑞尔视科技有限公司</p>
  </div>
</template>
<script>
  export default {
    name: 'aboutUs',
    created:function(){
        window.scrollTo(0,0);
    },
    methods:{
      
    }
  }
</script>
<style scoped>
  .aboutUs{width:60%;margin: 0 auto;}
  .top_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .sub_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .title{font-size: 20px;margin: 10px 0;}
  .content,th,td{text-indent:2em;font-size: 14px;line-height: 28px;}
  ul{margin-left: 40px;}
  li{font-size: 20px;line-height: 28px;}
  table{border-top: 1px solid #333333;margin: 20px 0;border-left: 1px solid #333333;}
  th{font-size: 14px;}
  th,td{border-right: 1px solid #333;border-bottom: 1px solid #333;padding: 20px;}
  .msc-r img{max-height: 350px!important;display: block;margin: 0 auto;}
</style>